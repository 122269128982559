import React from 'react';
import Env from '../env';
import { Card, CardContent, CardActions, CardHeader, CardMedia, Button, Box, makeStyles, Typography, Chip, Paper, Grid } from '@material-ui/core';
import logo from '../logo.png';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    kartica: {
        height: '100%',
        padding: theme.spacing(0),
        backgroundColor: '#ffffff',
        boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px'

    },
    skatla: {
        height: '100%'
    },
    paid: {
        backgroundColor: '#F7F7F7',
        color: '#44443E',
        fontWeight: 'bold'
    },
    free: {
        backgroundColor: '#ACD06C',
        color: '#567619',
        fontWeight: 'bold'

    },
    freemium: {
        backgroundColor: '#F3F9E9',
        color: '#567619',
        fontWeight: 'bold'

    },
    opensource: {
        backgroundColor: '#F7F7F7',
        color: '#567619',
        fontWeight: 'bold'

    },
    chip: {
        paddingTop: '3.5rem',
        paddingLeft: '2rem'
    },
    cardMedia: {
        width: '100%',
        paddingBottom: '40%',
        position: 'relative',
        backgroundColor: '#f2f2f2',
        textAlign: 'center',
        backgroundSize: 'contain',
    },
    cardContent: {
        padding: theme.spacing(3)
    },
    cardActions: {
        padding: theme.spacing(2),
        position: 'relative',
        width: '100%'

    },
    icon: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignSelf: 'flex-end',
        paddingRight: '2rem'
    },
    title: {
        paddingTop: '0.5rem',
        paddingLeft: '2.2rem',
        color: '#8c8c8c'
    },
    content: {
        color: '#8a8a8a',
        paddingLeft: '2.2rem',
        paddingRight: '2.2rem',
        height: '8rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '1.2rem',
        paddingBottom: '2rem'
    },
    image: {
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        width: '100%',
        objectFit: 'cover'
    },
    button: {
        marginLeft: '2.2rem',
        marginRight: '2.2rem',
        marginTop: '1rem',
        marginBottom: '2.2rem',
        backgroundColor: '#ffffff',
        border: '1.5px solid #ACD06C',
        borderRadius: '20px',
        '&:hover': {
            backgroundColor: '#ACD06C'
        }
    }
}));

function openLink(url) {
    window.open(url);
}

const logoStyles = makeStyles((theme) => ({
    ozadje: {
        position: 'relative',
        display: 'inline-block',
        '& svg': {
            display: 'block',
            maxWidth: '100%',
            height: 'auto'
        },
        '& img': {
            position: 'absolute',
            top: '1rem',
            left: 0,
            maxWidth: '100%'
        }
    }
}));
function ToolLogo(props) {
    const { logo } = props;
    const classes = logoStyles();
    return (<div className={classes.ozadje}>
        <svg width="105" height="94" viewBox="0 0 105 94" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.751953 57.0502V0.988525H104.102V57.0502C104.102 70.7252 85.1444 93.5736 50.8982 93.1295C16.652 92.6855 0.751953 70.5441 0.751953 57.0502Z" fill="#FAFAFA" />
        </svg>
        <img src={logo} />
    </div>);
}

export default function Tool(props) {
    const classes = useStyles();

    const paid = (<Chip label="PAID" component="div" className={classes.paid} />);
    const free = (<Chip label="FREE" component="div" className={classes.free} />);
    const freemium = (<Chip label="FREEMIUM" component="div" className={classes.freemium} />);
    const opensource = (<Chip label="OPENSOURCE" component="div" className={classes.opensource} />);


    const { data, learnMore } = props;

    let url = "";
    if (data.logo)
        url = Env.api + data.logo.url;
    else url = logo;
    return (
        <Box className={classes.skatla}>
            <Paper elevation={0} className={classes.kartica}>
                <Grid container>
                    <Grid item className={classes.chip} xs={6}>{(data.accessibility === "free") ? free : (data.accessibility === 'freemium') ? freemium : (data.accessibility === 'opensource') ? opensource : paid}</Grid>
                    <Grid item className={classes.icon} xs={6}>
                        <ToolLogo logo={url} />
                    </Grid>
                    <Typography variant="h5" component="h4" className={classes.title}>{data.title}</Typography>
                    <p className={classes.content}>
                        {data.description}
                    </p>
                    <Button variant="outlined" className={classes.button} disableElevation disableRipple disableTouchRipple onClick={learnMore}>Read more</Button>
                </Grid>
            </Paper>
        </Box>
    );
}