import React from 'react';
import { makeStyles, useTheme, FormControl, Select, Input, MenuItem, InputLabel } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2)
    }
}));



export default function Category(props) {
    const classes = useStyles();
    const { onChange, categories } = props;
    const [filterName, setFilterName] = React.useState('0');
    const handleChange = (event) => {
        setFilterName(event.target.value);
        onChange(event.target.value);
    };

    let catItems = [];

    for (const cat of categories) {
        let item = (<MenuItem key={cat.id} value={cat.id}>{cat.name}</MenuItem>);
        catItems.push(item);
    }

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="category-filter-label">Category</InputLabel>
            <Select
                labelId="category-filter-label"
                id="category-filter"
                value={filterName}
                onChange={handleChange}
            >
                <MenuItem value={0}><em>All categories</em></MenuItem>
                {catItems}
            </Select>
        </FormControl>
    );
}

