import React from 'react';
import { makeStyles, useTheme, FormControl, Select, Input, MenuItem, Checkbox, ListItemText, InputLabel } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2),
        minWidth: theme.spacing(12)
    }
}));


export default function PriceModel(props) {
    const classes = useStyles();

    const { onChange } = props;
    const [filterName, setFilterName] = React.useState('all');
    const options = ['opensource', 'free', 'paid', 'freemium'];

    const handleChange = (event) => {
        setFilterName(event.target.value);
        onChange(event.target.value);
    };

    let pmItems = [];

    for (const pricemodel of options) {
        let pmItem = (<MenuItem key={pricemodel} value={pricemodel}>{pricemodel}</MenuItem>);
        pmItems.push(pmItem);
    }


    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="pm-filter-label">Price model</InputLabel>
            <Select
                labelId="pm-filter-label"
                id="pm-filter"
                value={filterName}
                onChange={handleChange}
            >
                <MenuItem value='all'><em>All price models</em></MenuItem>
                {pmItems}
            </Select>
        </FormControl>
    );
}

