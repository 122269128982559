import React from 'react';
import { Typography, Box, makeStyles, Grid, Hidden } from '@material-ui/core';
import Search from './Search';
import Plant from '../plant1.png';


const useStyles = makeStyles((theme) => ({
    fikus: {
        position: 'relative',
        display: 'inline-block',
        '& svg': {
            display: 'block',
            maxWidth: '100%',
            height: 'auto'
        },
        top: 100,
        left: 100
    },
    slika: {
        position: 'absolute',
        top: -100,
        left: 0,
        content: `url(${Plant})`,
        marginBottom: '1rem'
    },
    content: {
        position: 'relative',
        [theme.breakpoints.up('960')]: {
            top: '12vh'

        },
    },
    subhead: {
        textTransform: 'uppercase',
        color: '#567619'
    },
    head: {
        fontWeight: 'bold'
    },
    container: {
        justifyContent: 'center'
    }
}));

function Fikus(props) {
    const classes = useStyles();
    return (<div className={classes.fikus}>
        <svg width="630" height="630" viewBox="0 0 630 630" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="314.952" cy="315.049" r="314.865" fill="white" />
        </svg>
        <div className={classes.slika}></div>
    </div>)
}


function Header(props) {
    const { onSearch } = props;
    const classes = useStyles();
    const [padding, setPadding] = React.useState('20vh');
    const handleSearch = (searchKey) => {
        if (searchKey.length > 0)
            setPadding('9.7vh');
        else setPadding('13.4vh');
        onSearch(searchKey);
    }
    return (<div style={{ paddingBottom: padding }}>
        <Grid container className={classes.container}>
            <Hidden smDown><Grid item><Fikus></Fikus></Grid></Hidden>
            <Grid item className={classes.content}>
                <Box paddingTop={15} display="flex" justifyContent="center">
                    <Typography variant="h1" className={classes.head} component="div">
                        Smart SMEs
                    </Typography>
                </Box>
                <Box paddingTop={0} display="flex" justifyContent="center">
                    <Typography variant="h5" className={classes.subhead} component="div">
                        Identified tools for SME's digitalization
                    </Typography>
                </Box>
                <Box paddingTop={2} display="flex" justifyContent="center">
                    <Search onSearch={handleSearch}></Search>
                </Box>
            </Grid>
        </Grid>
    </div>)
}

export default Header;