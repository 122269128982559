import React from 'react';
import { makeStyles, ButtonGroup, IconButton, Hidden, Button } from '@material-ui/core';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import EuroIcon from '@material-ui/icons/Euro';

const useStyles = makeStyles((theme) => ({
    sort: {
        margin: theme.spacing(2)
    },
    sortLabel: {
        color: 'rgba(0,0,0,0.54)',
        transformOrigin: 'top left',
        transform: 'scale(0.75)',
        fontSize: '1rem',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    },
    euroIcon: {
        fontSize: '1.3rem'
    }
}));

function Sort(props) {
    const classes = useStyles();
    const { onChange } = props;
    const handleSort = () => {
        switch (sort) {
            case 1:
                setSort(-1);
                break;
            default:
                setSort(1);
                break;
        }
    }
    const handlePriceSort = () => {
        switch (sort) {
            case 2:
                setSort(-2);
                break;
            default:
                setSort(2);
                break;
        }
    }
    const [sort, setSort] = React.useState(0);

    React.useEffect(() => {
        onChange(sort);
    }, [sort])

    return (
        <div className={classes.sort}>
            <div className={classes.sortLabel}>Sort</div>

            <ButtonGroup size="small" color="primary">
                <Button variant="text" disableElevation disableRipple disableTouchRipple aria-label="sort-title" onClick={handleSort}>
                    <SortByAlphaIcon />
                    <Hidden xsUp={sort !== -1}><ArrowDownwardIcon fontSize="small" /></Hidden>
                    <Hidden xsUp={sort !== 1}><ArrowUpwardIcon fontSize="small" /></Hidden>
                </Button>
                <Button variant="text" disableElevation disableRipple disableTouchRipple aria-label="sort-price" onClick={handlePriceSort}>
                    <EuroIcon className={classes.euroIcon} />
                    <Hidden xsUp={sort !== -2}><ArrowDownwardIcon fontSize="small" /></Hidden>
                    <Hidden xsUp={sort !== 2}><ArrowUpwardIcon fontSize="small" /></Hidden>
                </Button>
            </ButtonGroup>
        </div>

    );
}

export default Sort;