import React from 'react';
import { makeStyles, Grid, Button } from '@material-ui/core';
import Filters from './Filters/Filters';

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: 'rgba(238, 246, 224, 0.7)',
        maxWidth: '100%',
        padding: '10px'
    },
    button: {
        marginTop: theme.spacing(3)
    },
    price: {
        paddingLeft: '1rem !important',
        paddingRight: '1rem !important',

    },
    center: {
        display: 'flex',
        justifyContent: 'center'
    }
}));


function Filter(props) {
    const classes = useStyles();
    const { hidden, categories, languages, maxprice, onPriceModelChange, onPriceChange, onLanguageChange, onCategoryChange, onSortChange, onFilterApply } = props;
    return (<div hidden={hidden} className={classes.main}>
        <Grid container spacing={3}>
            <Grid item xs={12} md={2} className={classes.center}>
                <Filters.PriceModel onChange={onPriceModelChange}></Filters.PriceModel>
            </Grid>
            <Grid item xs={12} md={4} className={classes.price}>
                <Filters.Price maxprice={maxprice} onChange={onPriceChange}></Filters.Price>
            </Grid>
            <Grid item xs={12} md={2} className={classes.center}>
                <Filters.Language languages={languages} onChange={onLanguageChange}></Filters.Language>
            </Grid>
            <Grid item xs={12} md={2} className={classes.center}>
                <Filters.Category categories={categories} onChange={onCategoryChange}></Filters.Category>
            </Grid>
            {/* <Grid item xs={2}>
                <Button variant="outlined" onClick={onFilterApply} className={classes.button}>Apply filters</Button>
            </Grid> */}
            <Grid item xs={12} md={2} className={classes.center}>
                <Filters.Sort onChange={onSortChange}></Filters.Sort>
            </Grid>
        </Grid>
    </div>);
}

export default Filter;