import React from 'react';
import { makeStyles, Slider } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    price: {
        margin: theme.spacing(2)
    },
    priceLabel: {
        color: 'rgba(0,0,0,0.54)',
        transformOrigin: 'top left',
        transform: 'scale(0.75)',
        fontSize: '1rem',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',

    },
    slider: {
        marginTop: theme.spacing(1)
    }
}));

function Price(props) {
    const classes = useStyles();
    const { onChange, maxprice } = props;
    const [priceFilter, setPriceFilter] = React.useState([0, 10]);

    React.useEffect(() => {
        setPriceFilter([0, maxprice]);
    }, [maxprice]);

    const priceFilterChange = (event, newValue) => {
        setPriceFilter(newValue);
        onChange(newValue);
    };

    return (
        <div className={classes.price}>
            <div className={classes.priceLabel}>Price: {priceFilter[0]} € to {priceFilter[1]} € </div>
            <Slider
                className={classes.slider}
                value={priceFilter}
                onChange={priceFilterChange}
                valueLabelDisplay="auto"
                aria-labelledby="price-filter"
                min={0}
                max={maxprice}
            />
        </div>

    );
}

export default Price;