import React from 'react';
import './App.css';

import { Container, Box, makeStyles, Typography } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Menu from './components_new/Menu';
import Header from './components_new/Header';
import Filter from './components_new/Filter';
import Tools from './components_new/Tools';
import About from './components_new/About';
import Footer from './components_new/Footer';
import GoodPractices from './components_new/GoodPractices';
import { getTools, getCategories } from './services/smartsmes-service';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

import LearnMore from './components_new/MoreInfo/MoreInfoRoute';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    position: 'relative',
    backgroundColor: '#f7f7f7'
  },
  content: {
    paddingBottom: '4rem',
    marginTop: '3rem'
  },
  tools: {
    backgroundColor: '#ffffff'
  },
  toolsIntro: {
    paddingTop: '4rem',
    paddingBottom: '1rem',
    textAlign: 'center',
  },
  toolsTitle: {
    fontWeight: 'bold'
  },
  container: {
    paddingLeft: '5vw',
    paddingRight: '5vw'
  },
  practices: {
    backgroundColor: '#ffffff',
    paddingTop: '1rem',
    paddingBottom: '5rem'
  }
}));

function App() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#567619'
      },
      background: {
        default: '#e5e5e5'
      }
    }
  });
  const classes = useStyles();

  const [categories, setCategories] = React.useState([]);
  const [tools, setTools] = React.useState([]);
  const [filteredTools, setFilteredTools] = React.useState([]);
  const [languages, setLanguages] = React.useState([]);
  const [maxPrice, setMaxPrice] = React.useState(0);
  const [filter, setFilter] = React.useState({
    pricemodel: "all",
    price: [0, 1000],
    language: "all",
    category: 0
  });
  const [sort, setSort] = React.useState(0);
  const [searchActive, setSearchActive] = React.useState(false);

  React.useEffect(() => {
    const retrieveData = async () => {
      let categories = await getCategories();
      setCategories(categories);
      let tools = await getTools();
      setTools(tools);
      setFilteredTools(tools);
      let langs = retrieveLanguages(tools);
      setLanguages(langs);
      let maxprice = retrieveMaxPrice(tools);
      setMaxPrice(maxprice);
      setFilter({ ...filter, price: [0, maxprice] });
    }
    retrieveData();
  }, []);


  const handlePriceModelChange = async (pricemodel) => {
    setFilter({ ...filter, pricemodel: pricemodel });
    // handleFilter();
  }

  const handlePriceChange = (price) => {
    setFilter({ ...filter, price: price });
    // handleFilter();

  }

  const handleLanguageChange = (language) => {
    setFilter({ ...filter, language: language });
    // handleFilter();
  }

  const handleCategoryChange = (category) => {
    setFilter({ ...filter, category: category });
    // handleFilter();
  }

  React.useEffect(() => {
    handleFilter();
  }, [filter])

  const handleSortChange = async (sort) => {
    setSort(sort);
    let sorted = sortTools(sort, [].concat(filteredTools));
    // await setFilteredTools([]);
    setFilteredTools(sorted);
  }

  const handleFilter = async () => {
    let filtered = filterTools(filter, [].concat(tools));
    filtered = sortTools(sort, [].concat(filtered));
    // await setFilteredTools([]);
    setFilteredTools(filtered);
  }

  const handleSearch = async (searchKey) => {
    if (searchKey.length > 0)
      setSearchActive(true);
    else setSearchActive(false);
    let itemsFound = searchTools(searchKey, tools);
    setFilteredTools(itemsFound);
  }

  return (
    <Router>
      <ThemeProvider theme={theme}>

        <div className={classes.root}>
          <Switch>
            <Route exact path='/'>
              <section id="search" className={classes.container}>
                <Menu></Menu>
                <Header onSearch={handleSearch}></Header>
              </section>
              <section id="tools" className={classes.tools}>
                <Container maxWidth="xl" className={classes.container}>
                  <div className={classes.toolsIntro}>
                    <Typography variant="h4" component="h3" className={classes.toolsTitle}>Explore verified tools</Typography>
                    <p>Researchers have gathered more than fifty tools that can aid in digitalization of SMEs. Filter them to find the one tailored for you.</p>
                  </div>

                  <Filter hidden={searchActive} categories={categories} languages={languages} maxprice={maxPrice} onSortChange={handleSortChange} onPriceModelChange={handlePriceModelChange} onPriceChange={handlePriceChange} onLanguageChange={handleLanguageChange} onCategoryChange={handleCategoryChange} onFilterApply={handleFilter}></Filter>
                  <div className={classes.content}>
                    <Tools tools={filteredTools}></Tools>
                  </div>
                </Container>

              </section>

              <section id="practices" className={classes.practices} hidden={searchActive}>
                <GoodPractices></GoodPractices>
              </section>

              <section id="about" hidden={searchActive}>
                <About></About>
                <Footer></Footer>
              </section>
            </Route>
            <Route exact path='/tool/:id'>
              <LearnMore></LearnMore>
              <Footer></Footer>
            </Route>
          </Switch>

        </div>

      </ThemeProvider>
    </Router>

  )

}

const retrieveLanguages = (tools) => {
  let langArray = [];
  for (const tool of tools) {
    let languages = tool.languages.split(',');
    for (const language of languages) {
      let lang = language.trim().replace("and ", "");
      if (!langArray.includes(lang))
        langArray.push(lang);
    }
  }
  return langArray.sort();
}

const retrieveMaxPrice = (tools) => {
  let max = 0;
  for (const tool of tools) {
    if (tool.minprice > max)
      max = tool.minprice;
  }
  return max;
}

const filterTools = (filter, tools) => {
  const filtered = tools.filter((tool) => {
    if (filter.pricemodel === tool.accessibility || filter.pricemodel === 'all' || (filter.pricemodel === 'free' && tool.accessibility === 'opensource')) {
      if (filter.price[0] <= tool.minprice && filter.price[1] >= tool.minprice || tool.minprice === null) {
        if (filter.category === 0 || tool.categories.some((category) => (filter.category === category.id))) {
          if (tool.languages.includes(filter.language) || filter.language === 'all')
            return true;
          else return false;
        }
        else return false;
      }
      else return false;
    }
    else return false;
  });
  return filtered;
}

const sortTools = (sort, tools) => {
  if (sort % 2 === 0) { // po ceni
    let sortedTools = tools.sort((a, b) => (a.minprice > b.minprice) ? sort : ((b.minprice > a.minprice) ? -sort : 0));
    return sortedTools;
  }
  else { // po imenu
    let sortedTools = tools.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase()) ? sort : ((b.title.toLowerCase() > a.title.toLowerCase()) ? -sort : 0));
    return sortedTools;
  }
}

const searchTools = (searchKey, tools) => {
  const newItems = tools.filter(item => {
    if (item.title.toLowerCase().includes(searchKey.toLowerCase()) || item.keywords.toLowerCase().includes(searchKey.toLowerCase()) || item.producer.toLowerCase().includes(searchKey.toLowerCase()))
      return true;
    else return false;
  });
  return [].concat(newItems);
}

export default App;
