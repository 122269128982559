import React from 'react';

import { makeStyles, Typography, Grid, Paper } from '@material-ui/core';

import polMakThumb from '../factsheets/1-policy_makers.jpg';
import resCenThumb from '../factsheets/2-research_centres.jpg';
import privSecThumb from '../factsheets/3-private_sector.jpg';
import polMak from '../factsheets/1-policy_makers.pdf';
import resCen from '../factsheets/2-research_centres.pdf';
import privSec from '../factsheets/3-private_sector.pdf';


const useStyles = makeStyles((theme) => ({
    center: {
        textAlign: "center"
    },
    sheets: {
        paddingTop: '1rem',
    },
    kartica: {
        backgroundColor: '#ffffff',
        boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        position: 'relative',
        '&:hover': {
            '& $overlay': {
                opacity: 1
            },
            '& $image': {
                opacity: 0.7
            }
        }
    },
    image: {
        display: 'block',
        width: '100%',
        height: 'auto',
        opacity: 1
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: '100%',
        width: '100%',
        opacity: 0,
    },
    text: {
        color: 'black',
        textTransform: 'uppercase',
        fontSize: '3ch',
        position: 'absolute',
        top: '50%',
        left: '50%',
        WebkitTransform: 'translate(-50%, -50%)',
        msTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
    }


}));

export default function GoodPractices() {

    const classes = useStyles();

    return (
        <div className={classes.center}>
            <Typography variant="h5" component="h2">Good practices and tools for transferring knowledge on digitalization to SMEs</Typography>
            <Grid container spacing={0} className={classes.sheets}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <Paper className={classes.kartica}>
                                <img src={polMakThumb} alt="Policy makers fact sheet" className={classes.image} />
                                <a target="_blank" href={polMak}>
                                    <div class={classes.overlay}>
                                        <div class={classes.text}>Policy makers</div>
                                    </div>
                                </a>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className={classes.kartica}>
                                <img src={resCenThumb} alt="Research centres fact sheet" className={classes.image} />
                                <a target="_blank" href={resCen}>
                                    <div class={classes.overlay}>
                                        <div class={classes.text}>Research centres</div>
                                    </div>
                                </a>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className={classes.kartica}>
                                <img src={privSecThumb} alt="Private sector fact sheet" className={classes.image} />
                                <a target="_blank" href={privSec}>
                                    <div class={classes.overlay}>
                                        <div class={classes.text}>Private sector</div>
                                    </div>
                                </a>
                            </Paper>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={4}></Grid>
            </Grid>
        </div>);
}