import env from '../env';

const getTools = () => {
    return fetch(`${env.api}/tools`)
        .then(response => response.json());
}

const getTool = (id) => {
    return fetch(`${env.api}/tools/${id}`)
        .then(response => response.json());
}

const getCategories = () => {
    return fetch(`${env.api}/categories`)
        .then(response => response.json());
}

const getCategory = (id) => {
    return fetch(`${env.api}/categories/${id}`)
        .then(response => response.json());
}

export { getTools, getTool, getCategories, getCategory };