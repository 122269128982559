import React from 'react';
import { Grid, Grow, Container, makeStyles, Box, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Tool from './Tool';

const useStyles = makeStyles((theme) => ({

}));

export default function Tools(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { tools } = props;
    const [toolItems, setToolItems] = React.useState([]);
    const history = useHistory();

    const learnMore = (tool) => {
        history.push('/tool/' + tool.id);
    }

    React.useEffect(() => {
        let ti = [];
        for (const tool of tools) {
            let item = (<Grow key={tool.id} in={true}><Grid item xs={12} sm={6} md={6} lg={4} xl={3}><Tool data={tool} learnMore={() => learnMore(tool)} /></Grid></Grow>);
            ti.push(item);
        }
        setToolItems(ti);
    }, [tools])


    return (
        <Grid container spacing={4} style={{ paddingBottom: '5ch' }}>
            {toolItems}
        </Grid>
    );
}