import React from 'react';
import { makeStyles, useTheme, FormControl, Select, Input, MenuItem, InputLabel } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2)
    }
}));



export default function Language(props) {
    const classes = useStyles();

    const { onChange, languages } = props;
    const [filterName, setFilterName] = React.useState('all');

    const handleChange = (event) => {
        setFilterName(event.target.value);
        onChange(event.target.value);
    };

    let langItems = [];

    for (const language of languages) {
        let langItem = (<MenuItem key={language} value={language}>{language}</MenuItem>);
        langItems.push(langItem);
    }


    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="language-filter-label">Language</InputLabel>
            <Select
                labelId="language-filter-label"
                id="language-filter"
                value={filterName}
                onChange={handleChange}
            >
                <MenuItem value='all'><em>All languages</em></MenuItem>
                {langItems}
            </Select>
        </FormControl>
    );
}

