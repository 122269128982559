import React from 'react';
import { makeStyles, Typography, Link, Grid } from '@material-ui/core';
import logoEusalp from '../logo.png';
import logoFeri from '../logo-um-feri.png';
import logoEu from '../eu-flag.png';

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: '#f7f7f7',
        color: '#44443e',
        position: 'relative',
        bottom: '0',
        width: '100%',
        textAlign: 'center',
        minHeight: '10rem',
        display: 'flex',
        alignItems: 'center'
    },
    text: {
        display: 'flex',
        alignItems: 'center'
    },
    textFlex: {
        textAlign: 'left',
        paddingLeft: '10ch',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            padding: 0
        }
    },
    logo: {
        maxHeight: '4rem',
        [theme.breakpoints.down('md')]: {
            maxHeight: '3rem'
        },
        margin: '1rem'
    }
}));

export default function About(props) {
    const classes = useStyles();
    return (
        <footer className={classes.footer}>
            <Grid container>
                <Grid item xs={12} lg={7} className={classes.text}>
                    <div className={classes.textFlex}>
                        <Typography variant='caption' component='div'>This website was created as part of the Smart SME's project in 2020. All rights reserved.</Typography>
                        <Typography variant='caption' component='div'>Cofinancing by the European Parliament through the Alpine Region Preparatory Action Fund (ARPAF)</Typography>
                    </div>
                </Grid>
                <Grid item xs={12} lg={5}>
                    <Link href="https://www.alpine-region.eu/" target="_blank"><img src={logoEusalp} alt="EUSALP" className={classes.logo} /></Link>
                    <Link href="https://feri.um.si/en/" target="_blank"><img src={logoFeri} alt="UM FERI" className={classes.logo} /></Link>
                    <Link href="https://europa.eu/" target="_blank"><img src={logoEu} alt="European Union" className={classes.logo} /></Link>
                </Grid>
            </Grid>

        </footer>
    )
}

