import React from 'react';
import { makeStyles, Grid, Typography, Button, Box, Hidden } from '@material-ui/core';
import ReactPlayer from 'react-player';
import video from '../video.mp4';
import Plant from '../plant2.png';
import Ozadje from '../ozadje.png';
import swst from '../swst.pdf';

const useStyles = makeStyles((theme) => ({
    main: {
        background: `url(${Ozadje}) no-repeat`,
        backgroundSize: 'cover',
        display: 'block',
        position: 'relative',
        maxWidth: '100%',
        color: 'black',
        paddingBottom: '5rem',
        paddingTop: '5rem'
    },
    about: {
        paddingLeft: '5vw',
        paddingRight: '5vw',
        zIndex: 2
    },
    title: {
        color: '#2c2c2c'
    },
    aboutText: {
        paddingBottom: '2ch',
        paddingTop: '1ch',
        color: '#44443e'
    },
    aboutButton: {
        backgroundColor: '#ACD06C',
        border: '1.5px solid #ACD06C',
        borderRadius: '20px',
        '&:hover': {
            backgroundColor: '#ffffff'
        }
    },
    video: {
        margin: '0 auto',
    },
    videoButton: {
        backgroundColor: '#ACD06C',
        border: '1.5px solid #ACD06C',
        marginLeft: '5vw',
        borderRadius: '20px',
        '&:hover': {
            backgroundColor: '#ffffff'
        }
    },
    videoWrapper: {
        background: 'rgba(0,0,0,0.1)',
        margin: '0 auto',
        width: '640px',
        height: '360px'
    },
    grid: {
        alignItems: 'center'
    },
    rastlina: {
        position: 'absolute',
        top: '-5rem',
        left: '-5rem',

    },
    publications: {
        paddingTop: '3ch'
    },
    listSmall: {
        fontSize: "0.8rem"
    }
}));

export default function About(props) {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <Hidden smDown>
                <div className={classes.rastlina}>
                    <img src={Plant} />
                </div>
            </Hidden>

            <Grid container spacing={0} className={classes.grid}>
                <Grid item xs={12} lg={7} >
                    <div className={classes.about}>
                        <Typography variant="h4" component="h2" className={classes.title}>About the project</Typography>
                        <div className={classes.aboutText}>
                            The aim of the Smart SMEs project is to understand to what extent SMEs that produce, process and apply natural fibres use digitalization tools and approaches. The project will also evaluate obstacles that prevent SMEs from exploiting the full potential of digital solutions.
                        </div>
                        <Button variant="outlined" href="https://www.alpine-region.eu/projects/smart-smes" target="_blank" className={classes.aboutButton}>Project website</Button>
                        <div className={classes.publications}>
                            <Typography variant="h5" component="h3" className={classes.title}>Publications</Typography>
                            <ul className={classes.listSmall}>
                                <li><a target="_blank" href={swst}>Digitalization rate of SMEs involved in the wood fibers value chains in the alpine region</a></li>
                                <li><a target="_blank" href="https://ceciis.foi.hr/sites/default/files/ceciis2020/ICTEI/CECIIS-2020_paper_23.pdf">Good Practices and Tools for Digitalization of SMEs in the Natural Fiber-based Value Chains</a></li>
                                <li><a target="_blank" href="https://doi.org/10.3390/su12198121">Supporting the sustainability of natural fiber-based value chains of SMEs through digitalization</a></li>
                            </ul>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} lg={5} >
                    <Hidden smDown>
                        <div className={classes.videoWrapper}>
                            <ReactPlayer className={classes.video} url={video} controls={true} light={true} pip={true}></ReactPlayer>

                        </div>
                    </Hidden>
                    <Hidden mdUp>
                        <Button variant="outlined" href={video} target="_blank" className={classes.videoButton}>Presentation video</Button>

                    </Hidden>
                </Grid>
            </Grid>

        </div >
    )
}