import React from 'react';
import { getTool } from '../../services/smartsmes-service';
import { Grid, makeStyles, Typography, Paper, Button, Chip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Env from '../../env';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: '10vh',
        paddingLeft: '5vw',
        paddingRight: '5vw',
        paddingBottom: '5vh'
    },
    okvir: {
        backgroundColor: '#ffffff',
        boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        padding: '2rem'
    },
    description: {
        paddingTop: '2vh'
    },
    button: {
        marginBottom: '2.2rem',
        backgroundColor: '#ffffff',
        border: '1.5px solid #ACD06C',
        borderRadius: '20px',
        '&:hover': {
            backgroundColor: '#ACD06C'
        }
    },
    logo: {
        width: '100%',
        borderRadius: '20px',
        boxShadow: '2px 4px 4px rgba(0,0,0, 0.1)',
        marginBottom: '1rem'
    },
    subtitle: {
        marginRight: '1rem',
        textTransform: 'uppercase'
    },
    content: {
        color: '#8a8a8a',
        fontSize: '0.8em',
        textDecoration: 'none'
    },
    row: {
        marginTop: '0.6rem'
    },
    desniStolpec: {
    },
    screenshots: {
        paddingTop: '2rem',
    }
}));


const getUrl = (path) => {
    let url = Env.api + path;
    return url;
}




export default function MoreInfo(props) {
    const classes = useStyles();
    const history = useHistory();

    const { id } = useParams();
    const [tool, setTool] = React.useState({});
    const [url, setUrl] = React.useState("");
    const [pricing, setPricing] = React.useState([]);
    const [limits, setLimits] = React.useState([]);
    const [screenshots, setScreenshots] = React.useState([]);

    const handleBack = () => {
        history.push('/');
    }
    React.useEffect(() => {
        const obtainTool = async () => {
            let tool = await getTool(id);
            setTool(tool);
            let naslov = getUrl(tool.logo.url);
            setUrl(naslov);
            if (tool.prices) {
                const pricing = tool.prices.split("\n");
                let pricingB = [];
                for (const price of pricing) {
                    let p;
                    if (price.startsWith('*'))
                        p = price.slice(2);
                    else
                        p = price;
                    pricingB.push((<li>{p}</li>));
                }
                setPricing(pricingB);
            }

            if (tool.limit) {
                const limits = tool.limit.split("\n");
                let limitsB = [];
                for (const limit of limits) {
                    let l;
                    if (limit.startsWith('*'))
                        l = limit.slice(2);
                    else
                        l = limit;
                    limitsB.push((<li>{l}</li>))
                }
                setLimits(limitsB);
            }

            if (tool.screenshots) {
                let screenshots = [];
                for (const ss of tool.screenshots) {
                    let path = getUrl(ss.url);
                    screenshots.push((<div><img src={path} /></div>));
                }
                setScreenshots(screenshots);
            }

            if (tool.video) {
                let ss = [].concat(screenshots);
                let video = (<div style={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
                    <ReactPlayer url={tool.video} controls={true} light={false} pip={true}></ReactPlayer>
                </div>);
                ss.push(video);
                setScreenshots(ss);
            }

        }
        obtainTool();
    }, []);




    return (
        <div className={classes.container}>
            <Button disableTouchRipple disableRipple disableElevation variant="outlined" className={classes.button} onClick={handleBack}>Back to tools</Button>
            <Typography variant="h3" component="h1">{tool.title}</Typography>
            <Grid container spacing={5}>
                <Grid item xs={12} lg={8}>
                    <div className={classes.description}>
                        {tool.description}
                    </div>
                    <div className={classes.screenshots}>
                        <Carousel>
                            {screenshots}
                        </Carousel>
                    </div>
                </Grid>
                <Grid item xs={12} lg={4} className={classes.desniStolpec}>
                    <img src={url} className={classes.logo} />
                    <Paper elevation={0} className={classes.okvir}>
                        <Typography variant="h6" component="h2">About</Typography>
                        <div className={classes.row}><Typography variant="subtitle2" component="span" className={classes.subtitle}>Producer </Typography><span className={classes.content}>{tool.producer}</span></div>
                        <div className={classes.row}><Typography variant="subtitle2" component="span" className={classes.subtitle}>Prices </Typography><span className={classes.content}><ul>{pricing}</ul></span></div>
                        <div className={classes.row}><Typography variant="subtitle2" component="span" className={classes.subtitle}>Limits </Typography><span className={classes.content}><ul>{limits}</ul></span></div>
                        <div className={classes.row}><Typography variant="subtitle2" component="span" className={classes.subtitle}>URL </Typography><a className={classes.content} href={tool.url} target="_blank">{tool.url}</a> </div>
                        <div className={classes.row}><Typography variant="subtitle2" component="span" className={classes.subtitle}>Languages </Typography>
                            <span className={classes.content}>{tool.languages}</span></div>

                    </Paper>
                </Grid>
            </Grid>

        </div>
    );
}
