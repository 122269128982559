import React from 'react';
import { makeStyles, Box, Button, Hidden, Grid } from '@material-ui/core';
import { Link } from 'react-scroll'

const useStyles = makeStyles((theme) => ({
    menuButton: {
        color: '#44443E'
    },
    menuSmall: {
        paddingTop: '2vh',
        textAlign: 'center'
    }
}));

function Menu(props) {
    const classes = useStyles();
    return (<div>
        <Hidden smDown>
            <Box paddingX={2} display="flex" justifyContent="flex-end">
                <Box p={3}>
                    <Link to="search" spy={true} smooth={true} duration={250}><Button disableElevation disableRipple disableTouchRipple className={classes.menuButton} >Search</Button></Link>
                </Box>
                <Box p={3}>
                    <Link to="tools" spy={true} smooth={true} duration={250}><Button disableElevation disableRipple disableTouchRipple className={classes.menuButton} >Tools</Button></Link>
                </Box>
                <Box p={3}>
                    <Link to="practices" spy={true} smooth={true} duration={250}><Button disableElevation disableRipple disableTouchRipple className={classes.menuButton} >Good practices</Button></Link>
                </Box>
                <Box p={3}>
                    <Link to="about" spy={true} smooth={true} duration={250}><Button disableElevation disableRipple disableTouchRipple className={classes.menuButton} >About</Button></Link>
                </Box>
            </Box>
        </Hidden>
        <Hidden mdUp>
            <Grid container className={classes.menuSmall}>
                <Grid item xs={6}>
                    <Link to="search" spy={true} smooth={true} duration={250}><Button disableElevation disableRipple disableTouchRipple className={classes.menuButton} >Search</Button></Link>
                </Grid>
                <Grid item xs={6}>
                    <Link to="tools" spy={true} smooth={true} duration={250}><Button disableElevation disableRipple disableTouchRipple className={classes.menuButton} >Tools</Button></Link>

                </Grid>
                <Grid item xs={6}>
                    <Link to="practices" spy={true} smooth={true} duration={250}><Button disableElevation disableRipple disableTouchRipple className={classes.menuButton} >Good practices</Button></Link>

                </Grid>
                <Grid item xs={6}>
                    <Link to="about" spy={true} smooth={true} duration={250}><Button disableElevation disableRipple disableTouchRipple className={classes.menuButton} >About</Button></Link>

                </Grid>
            </Grid>
        </Hidden>
    </div>)
}

export default Menu;